import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import Header from "../header/header";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state == "goToSettings") {
      navigate("/settings", { state: "connect" });
      setFirstTime(false);
      return;
    }
    if (firstTime) {
      if (location.pathname != "/dashboard") {
        navigate("/dashboard");
        setFirstTime(false);
      }
    }
  }, []);

  return (
    <>
      <div className="Dashboard-main-container d-flex overflow-x-hidden">
        <div className="left-content">
          <Sidebar show={show} setShow={setShow} />
        </div>

        <div className="right_content w-100">
          <Header show={show} setShow={setShow} />
          <div className="page-dom">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
