import React, { useContext, useEffect, useRef, useState } from "react";
import "./agentCreation.scss";
import Inputdash from "../Inputdash/inputdash";
import { Radio, RadioGroup } from "react-radio-group";
import Button from "../button/button";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { DataContext } from "../../App";
import CustomSelect from "../customselect/customselect";
import { Getvoices } from "../../helperFunctions/utils/elevenLabs";
import { AllNumbers } from "../../helperFunctions/agentNumbers/agentNumbers";

function AgentCreation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [voiceRadio, setVoiceRadio] = useState("default");
  const [allknowledgebases, setAllknowledgebases] = useState([]);
  const { data, setData } = useContext(DataContext);
  const [customapi, setCustomapi] = useState("");
  const [getnumber, setGetnumber] = useState([]);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(false);
  const [selectednumber, setSelectednumber] = useState(false);
  const [customvoice, setCustomvoice] = useState(false);
  const [currentBasename, setCurrentBasename] = useState("");
  const customOptionRef = useRef();

  // console.log(location.state);

  useEffect(() => {
    const handleOutsideTheCustomOptionClick = (e) => {
      if (
        customOptionRef.current &&
        !customOptionRef.current.contains(e.target)
      ) {
        setCustomvoice(false);
      }
    };

    document.addEventListener("click", handleOutsideTheCustomOptionClick);

    return () =>
      document.removeEventListener("click", handleOutsideTheCustomOptionClick);
  }, []);
  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleShow = () => {
    handleAllknowledgebases();
    setShow(true);
  };
  const navigateToscript = () => {
    if (
      !data.AgentName ||
      // !data.CompanyName ||
      !data.greetings ||
      !data.knowledgeBaseId ||
      !data.phoneNumber ||
      !data.livetransfer ||
      // !data.FallBackNote ||
      !data.audioId
    ) {
      toast.error("All fields must be filled out before proceeding.", {
        toastId: "nexttoast",
      });
      return;
    }
    navigate("/script");
  };

  const handleCustomapichange = (event) => {
    setCustomapi(event.target.value);
  };
  const handleVoiceChange = async (e) => {
    setVoiceRadio(e);
    console.log(e, customapi);
    // const res = await Getvoices(e == "customize" ? customapi : "");

    if (e == "default") {
      Getvoices(e == "customize" && customapi)
        .then((res) => {
          console.log(res);
          setVoices(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // setCustomvoice(true);
    }
  };

  useEffect(() => {
    if (!data.AgentTitle) {
      setData((prevData) => {
        const updatedData = { ...prevData, AgentTitle: location.state.data };
        console.log(updatedData.AgentTitle); // Logs the updated value

        return updatedData;
      });
    }

    return () => {};
  }, [location.state]);
  const allnumberdata = async () => {
    AllNumbers()
      .then((res) => {
        setGetnumber(res);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const allvoices = async () => {
    if (voiceRadio == "customize" && customapi == "") {
      toast.error("Please enter ID ", { toastId: "customerr" });
    } else {
      Getvoices(customapi)
        .then((res) => {
          setVoices(res);
          if (voiceRadio == "customize") {
            setCustomvoice(true);
          }
          console.log(res);
        })
        .catch((err) => {
          setCustomvoice(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    allvoices();
    allnumberdata();
  }, []);
  useEffect(() => {
    console.log(data);
  }, [data]);

  const Apiurl = process.env.REACT_APP_API_KEY;
  const handleAllknowledgebases = () => {
    setSpinner(true);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Apiurl}/get-all-knowledgebases`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    console.log("Create check", config);

    axios
      .request(config)
      .then((res) => {
        setAllknowledgebases(res.data.allKnowledgeBases);
        console.log(res.data.allKnowledgeBases);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });
  };

  useEffect(() => {
    console.log("selectedVoice", selectedVoice);
  }, [selectedVoice]);
  const [defaultRequiredVoice, setDefaultRequiredVoice] = useState(false);
  useEffect(() => {
    if (data.audioId && voices.length > 0) {
      console.log("well", voices);
      console.log("well", data.audioId);
      console.log("well", voices);

      const requiredVoice = voices.find(
        (item) => item.voice_id == data.audioId
      );
      console.log("ams", requiredVoice);
      if (requiredVoice) {
        setSelectedVoice(data.audioId);

        setDefaultRequiredVoice(
          "Name : " +
            requiredVoice.name +
            ", " +
            "Accent : " +
            requiredVoice.labels.accent +
            ", " +
            "Description : " +
            requiredVoice.labels.description +
            ", " +
            "Age : " +
            requiredVoice.labels.age +
            ", " +
            "Gender : " +
            requiredVoice.labels.gender
        );
      }
    }
  }, [voices]);

  useEffect(() => {
    console.log(data);
  }, []);

  return (
    <div className="dash-main">
      <div className="createAgent-outer">
        <div className="topTabs-outer">
          <div className="top-tabs">
            <div className="top-tab">
              <div className="number-div active-div">
                <p className="small-para Carmen-semibold-font">1</p>
              </div>
              <p className="small-para Carmen-semibold-font active-agent ">
                Create Agent
              </p>
            </div>
            <div className="top-line"></div>
            <div className="top-tab ">
              <div className="number-div">
                <p className="small-para Carmen-semibold-font ">2 </p>
              </div>
              <p className="small-para Carmen-semibold-font tab-agent">
                Update Script
              </p>
            </div>
            <div className="top-line"></div>
            <div className="top-tab">
              <div className="number-div">
                <p className="small-para Carmen-semibold-font ">3</p>
              </div>
              <p className="small-para Carmen-semibold-font tab-agent">
                Advanced Setting
              </p>
            </div>
          </div>
        </div>
        <section className="agentCreation-sec">
          <h3 className="heading primarycolor Carmen-semibold-font tab-agent">
            Create Agent
          </h3>
          <div className="creation-content">
            <div className="ist">
              <Inputdash
                type="text"
                label="Agent Name"
                placeholder="James"
                onchange={handleChange}
                value={data.AgentName}
                name="AgentName"
              />
              <Inputdash
                type="number"
                label="Transfer Call"
                placeholder="Add number"
                onchange={handleChange}
                value={data.livetransfer}
                name="livetransfer"
              />
              {/* <Inputdash
                type="text"
                label="Industry"
                placeholder="Add Industry"
                onchange={handleChange}
                value={data.CompanyName}
                name="CompanyName"
              /> */}
            </div>
            <div className="ist">
              <Inputdash
                label="Welcome Message"
                value={data.greetings}
                onchange={handleChange}
                name="greetings"
              />
              <div className="selectnmbr-outer">
                <h5 className="darkcolor Carmen-semibold-font">Phone Number</h5>
                <div className="number-div">
                  <CustomSelect
                    key={"phone"}
                    placeholder="Select phone number"
                    height={50}
                    options={getnumber?.map((number) => ({
                      value: number.phone_number,
                      label: number.phone_number,
                    }))}
                    showSearch
                    value={
                      data.phoneNumber
                        ? data.phoneNumber
                        : selectednumber
                        ? selectednumber
                        : "Select phone no."
                    }
                    wrapperClasses="min-w-[130px] w-full"
                    onChange={(e) => {
                      console.log("val", e);
                      setSelectednumber(e);
                      setData((prev) => ({ ...prev, phoneNumber: e }));
                    }}
                  />
                  {/* <div
                  className="number-selector pointer"
                  onClick={handleSelectnumber}
                >
                  <h4
                    className="greycolor Carmen-regular-font"
                    style={{ opacity: "0.2" }}
                  >
                    {getnumber.length > 0 ? getnumber : "Select Ph no."}
                  </h4>
                  {selectnumber ? (
                    <Icon
                      icon="mingcute:up-line"
                      width="24"
                      height="24"
                      style={{ color: "black", opacity: "0.5" }}
                    />
                  ) : (
                    <Icon
                      icon="mingcute:down-line"
                      width="24"
                      height="24"
                      style={{ color: "black", opacity: "0.5" }}
                    />
                  )}
                  <div
                    className={`number-options customscrollbar ${
                      selectnumber ? "active" : "deactive"
                    }`}
                  >
                    {rounded ? (
                      <span className="spinner"></span>
                    ) : (
                      getnumber.map((item, index) => (
                        <p
                          id={index}
                          onClick={() => {
                            setData((prev) => ({
                              ...prev,
                              phoneNumber: item,
                            }));
                          }}
                          className="pointer"
                        >
                          {item}
                        </p>
                      ))
                    )}
                  </div>
                </div> */}
                </div>
              </div>
            </div>
            {/* <Inputdash
              label="Fallback Note"
              value={data.FallBackNote}
              onchange={handleChange}
              name="FallBackNote"
            /> */}
            <div className="selectnmbr-outer">
              <h6 className="darkcolor Carmen-semibold-font">Select Voice</h6>
              <div className="voice-div">
                {/* <div className="voice-top"> */}
                {/* <RadioGroup
                    name="Voice "
                    className="gender-radio"
                    value={voiceRadio}
                    onChange={handleVoiceChange}
                  >
                    <label>
                      <Radio
                        value="default"
                        checked={voiceRadio == "default" ? true : false}
                      />
                      Default
                    </label>
                    <label>
                      <Radio value="customize" />
                      Custom
                    </label>
                  </RadioGroup> */}
                {/* </div> */}
                {/* <div className="voice-bottom"> */}
                {/* {voiceRadio === "customize" ? (
                    <>
                      <div className="d-flex align-items-center gap-1 custom-voice">
                        <input
                          type="text"
                          placeholder="Paste Eleven labs ID here"
                          value={customapi}
                          name="customapi"
                          onChange={handleCustomapichange}
                        />
                        <Icon
                          icon="iconamoon:search-thin"
                          width={"20px"}
                          height={"20px"}
                          className="pointer"
                          onClick={allvoices}
                        />
                      </div>
                      {customvoice ? (
                        <div
                          className="customvoices-data"
                          ref={customOptionRef}
                        >
                          {voices.voices.map((item, index) => (
                            <p
                              onClick={() => {
                                setCustomvoice(false);
                                setCustomapi(item.name);
                                setData((prev) => ({
                                  ...prev,
                                  audioId: item.voice_id,
                                }));
                              }}
                              className="pointer"
                            >
                              Name: {item.name + " "}
                              Accent: {item.labels.accent + " "}
                              Description: {item.labels.description + " "}
                              Age: {item.labels.age + " "}
                              Gender: {item.labels.gender + " "}
                            </p>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : ( */}
                <CustomSelect
                  key={"voice"}
                  placeholder={"Select Voice"}
                  height={50}
                  onChange={(e) => {
                    console.log("val", e);
                    setSelectedVoice(e);
                    setData((prev) => ({ ...prev, audioId: e }));
                  }}
                  defaultActiveFirstOption={true}
                  value={
                    selectedVoice
                      ? selectedVoice
                      : defaultRequiredVoice
                      ? defaultRequiredVoice
                      : "Select Voice"
                  }
                  defaultValue={voices?.[0]?.voice_id || undefined}
                  options={voices?.map((voice) => ({
                    value: voice.voice_id,
                    label: (
                      <>
                        {"Name : " + voice.name + ", "}
                        {"Accent : " + voice.labels.accent + ", "}
                        {"Description : " + voice.labels.description + ", "}
                        {"Age : " + voice.labels.age + ", "}
                        {"Gender : " + voice.labels.gender}
                      </>
                    ),
                  }))}
                  showSearch
                  wrapperClasses="min-w-[130px] w-full"
                  // onChange={handleChangePhone}
                />
                {/* )} */}
              </div>
              {/* </div> */}
            </div>

            <div className="third ">
              <div className="add-base">
                <div className="base-detail">
                  <img src="/assets/icons/book.svg" alt="..." />
                  <p className="greycolor Carmen-regular-font">
                    {currentBasename
                      ? currentBasename
                      : location?.state?.basename}
                  </p>
                </div>
                <Button
                  text="Add Knowledge Base"
                  className="primary-button fs-16 "
                  maxWidth="270px"
                  width="100%"
                  padding="12px"
                  onClick={handleShow}
                />
              </div>
            </div>
            <div className="bottom">
              <Button
                text="Back"
                className="secondary-btn w-100 fs-20"
                maxWidth="157px"
                padding="12px 36px"
                onClick={() => navigate("/dashboard")}
              />
              <Button
                text="Next"
                className="primary-button w-100 fs-20 "
                maxWidth="157px"
                padding="12px 36px"
                onClick={navigateToscript}
              />
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="selectbaseModal"
          >
            <Modal.Body>
              <img
                src="/assets/icons/modalclose.svg"
                alt="..."
                className="close-btn pointer"
                onClick={handleClose}
              />
              <h2 className="Carmen-semibold-font primarycolor">
                Select Knowledge Base
              </h2>
              {spinner ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "100px" }}
                >
                  <span className="spinner"></span>
                </div>
              ) : allknowledgebases.length < 1 ? (
                <>
                  <p className="Carmen-medium-font greycolor">
                    No Knowledge base found
                  </p>
                  <Button
                    text="Create"
                    className="primary-button w-100 fs-16 "
                    maxWidth="100px"
                    padding="10px 20px"
                    onClick={() => {
                      navigate("/knowledge-base");
                    }}
                  />
                </>
              ) : (
                <div className="bases customscrollbar">
                  {allknowledgebases.map((item, index) => (
                    <div
                      className="pointer"
                      id={item._id}
                      onClick={() => {
                        setCurrentBasename(item.name);
                        setData((prev) => ({
                          ...prev,
                          knowledgeBaseId: item._id,
                        }));
                        handleClose();
                      }}
                    >
                      <img src="/assets/icons/folder.svg" alt="..." />
                      <p className="Carmen-medium-font greycolor">
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </Modal.Body>
          </Modal>
          <Outlet />
        </section>
      </div>
    </div>
  );
}

export default AgentCreation;
