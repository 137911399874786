import React, { useContext, useEffect, useRef, useState } from "react";
import "./advanceScript.scss";
import Button from "../button/button";
import "react-range-slider-input/dist/style.css";
import RangeSlider from "react-range-slider-input";
import ReactSlider from "react-slider";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Modal } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { DataContext } from "../../App";
import { toast } from "react-toastify";

import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import Item from "antd/es/list/Item";
function AdvanceScript() {
  const navigate = useNavigate();
  const hubspot = process.env.REACT_APP_AWS_BASE_URL;
  const [disableText, setDisableText] = useState(true);
  const { updateagent, setUpdateagent } = useContext(DataContext);
  const [showmodal, setShowmodal] = useState(false);
  const modalRef = useRef(null);
  const [show, setShow] = useState(false);
  const { data, setData } = useContext(DataContext);
  const [createbtnloader, setCreatebtnloader] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [meetingLinks, setMeetingLinks] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [meetingLoader, setMeetingLoader] = useState(false);

  // Function to handle click event and set active index
  const handleClick = (index) => {
    setActiveIndex(index);
  };
  useEffect(() => {
    console.log(":Dsd", data);
  }, []);

  const Apiurl = process.env.REACT_APP_API_KEY;
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Df", typeof value);

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleShow = () => {
    if (
      !data.Temperature ||
      !data.Maximum_Tokens ||
      // !data.Top_P ||
      // !data.Frequency_Penality ||

      !data.Model
    ) {
      toast.error("All fields must be filled out before proceeding.", {
        toastId: "nexttoast",
      });
      return;
    } else if (!data.hubspot_meetinglink) {
      toast.error("Select One meeting!", {
        toastId: "nexttoast",
      });
      return;
    } else {
      createAgent();
    }
  };
  const handleUpdate = () => {
    if (
      !data.Temperature ||
      !data.Maximum_Tokens ||
      // !data.Top_P ||
      // !data.Frequency_Penality ||
      !data.Model
    ) {
      toast.error("All fields must be filled out before proceeding.", {
        toastId: "nexttoast",
      });
      return;
    } else if (!data.hubspot_meetinglink) {
      toast.error("Select One meeting!", {
        toastId: "nexttoast",
      });
      return;
    } else {
      updateAgentApi();
    }
  };

  const createAgent = () => {
    const logintoken = localStorage.getItem("authToken");
    setCreatebtnloader(true);
    axios
      .post(
        `${Apiurl}/CreateAgent`,
        {
          AgentName: data.AgentName,
          AgentTitle: data.AgentTitle,
          FallBackNote: "Fallback note",
          greetings: data.greetings,
          Script: data.Script,
          Scriptshow: data.Scriptshow,
          confinement: data.confinement,
          Temperature: data.Temperature,
          Maximum_Tokens: data.Maximum_Tokens,
          audioId: data.audioId,
          Top_P: "1",
          Frequency_Penality: "1",
          Presence_Penality: "1",
          Model: data.Model,
          phoneNumber: data.phoneNumber,
          knowledgeBaseId: data.knowledgeBaseId,
          livetransfer: data.livetransfer,
          hubspot_meetinglink: data.hubspot_meetinglink,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${logintoken}`,
          },
        }
      )

      .then((res) => {
        const token = res.data.token;
        console.log(res.data);

        if (data.AgentTitle == "Inbound_Call") {
          axios
            .post(
              `${Apiurl}/InboundCallweb`,
              {
                data: {
                  inboundCall: `${hubspot}/inbound_Call/${res.data.data}`,
                  agentID: res.data.data,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${logintoken}`,
                },
              }
            )
            .then((specific) => {
              // setShow(true);
              inboundConfig(res.data.data);

              console.log("ok");
            })
            .catch((error) => {
              toast.error(error?.response?.data?.message, {
                toastId: "loginfailure",
              });
              console.log("erore");

              setCreatebtnloader(false);
            });
        } else if (data.AgentTitle == "Outbound_Call") {
          setShow(true);
          navigate("/agents");
          toast.success("Agent created successfully!", {
            toastId: "agentsuccess",
          });
          setCreatebtnloader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message, { toastId: "loginfailure" });

        setCreatebtnloader(false);
        return;
      });
  };

  const updateAgentApi = () => {
    setCreatebtnloader(true);
    const logintoken = localStorage.getItem("authToken");
    axios
      .post(
        `${Apiurl}/updateAgent/${data._id}`,
        {
          AgentName: data.AgentName,
          AgentTitle: data.AgentTitle,
          FallBackNote: "Fallback note",
          greetings: data.greetings,
          Script: data.Script,
          Scriptshow: data.Scriptshow,
          confinement: data.confinement,
          Temperature: data.Temperature,
          Maximum_Tokens: data.Maximum_Tokens,
          audioId: data.audioId,
          Top_P: "1",
          Frequency_Penality: "1",
          Presence_Penality: "1",
          Model: data.Model,
          phoneNumber: data.phoneNumber,
          knowledgeBaseId: data.knowledgeBaseId,
          livetransfer: data.livetransfer,
          hubspot_meetinglink: data.hubspot_meetinglink,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${logintoken}`,
          },
        }
      )

      .then((res) => {
        const token = res.data.token;
        inboundConfig(data._id);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message, { toastId: "loginfailure" });

        setCreatebtnloader(false);
        return;
      });
  };

  const inboundConfig = (id) => {
    axios
      .post(`${hubspot}/add_inbound_call_config01`, {
        Agent_id: id,
      })
      .then((response) => {
        navigate("/agents");
        setShow(true);
        toast.success("Agent created successfully!", {
          toastId: "agentsuccess",
        });

        setCreatebtnloader(false);
      })
      .catch((error) => {
        navigate("/agents");

        toast.success("Agent created successfully!", {
          toastId: "agentsuccess",
        });
        setCreatebtnloader(false);
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleshowModal = () => {
    setShowmodal(!showmodal);
  };

  const handleOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowmodal(false);
    }
  };

  const navigateToscript = () => {
    navigate("/script");
  };

  const getMeetingLinks = () => {
    setMeetingLoader(true);
    axios
      .post(
        `${hubspot}/getmeetinglinks?token=${process.env.REACT_APP_HUBSPOT_API_TOKEN}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setMeetingLinks(response?.data?.results);
        setMeetingLoader(false);
        const activeIndex = response?.data?.results.findIndex(
          (item) => item.link === data.hubspot_meetinglink
        );
        if (activeIndex !== -1) {
          setActiveIndex(activeIndex);
        }
      })
      .catch((err) => {
        console.log(err);
        setMeetingLoader(false);
      });
  };

  // Search

  const filteredMeetingLinks = meetingLinks.filter((item) =>
    item.slug.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    getMeetingLinks();
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, []);

  return (
    <div className="dash-main">
      <div className="advancedscript-outer">
        <div className="topTabs-outer">
          <div className="top-tabs">
            <div className="top-tab">
              <div className="number-div active-div">
                <p className="small-para Carmen-semibold-font">1</p>
              </div>
              <p className="small-para Carmen-semibold-font active-agent ">
                Create Agent
              </p>
            </div>
            <div className="top-line active-div"></div>
            <div className="top-tab ">
              <div className="number-div active-div">
                <p className="small-para Carmen-semibold-font ">2 </p>
              </div>
              <p className="small-para Carmen-semibold-font active-agent">
                Update Script
              </p>
            </div>
            <div className="top-line active-div"></div>
            <div className="top-tab">
              <div className="number-div active-div">
                <p className="small-para Carmen-semibold-font ">3</p>
              </div>
              <p className="small-para Carmen-semibold-font active-agent">
                Advanced Setting
              </p>
            </div>
          </div>
        </div>
        <section className="advancescript-sec">
          <div className="head">
            <h3 className="heading primarycolor Carmen-semibold-font">
              Advanced
            </h3>
            {disableText ? (
              <Button
                text="Edit"
                className="primary-button w-100 fs-16 "
                maxWidth="92px"
                padding="10px 20px"
                onClick={() => {
                  setDisableText(false);
                }}
              />
            ) : (
              <Button
                text="Save"
                className="primary-button w-100 fs-16 "
                maxWidth="92px"
                padding="10px 20px"
                onClick={() => {
                  setDisableText(true);
                }}
              />
            )}
          </div>
          <div className="range-sec">
            <div className="range-div">
              <div className="detail">
                <h6
                  className={`Carmen-semibold-font darkcolor ${
                    disableText === true ? "opacity-light" : "opacity-dark"
                  }`}
                >
                  Temperature
                </h6>
                <h6
                  className={`Carmen-semibold-font darkcolor value ${
                    disableText === true ? "opacity-light" : "opacity-dark"
                  }`}
                >
                  {data.Temperature}
                </h6>
              </div>
              <input
                type="range"
                min="0.1"
                max="2"
                value={data.Temperature}
                name="Temperature"
                onChange={handleChange}
                step={0.1}
                disabled={disableText}
              />
            </div>
            <div className="range-div">
              <div className="detail">
                <h6
                  className={`Carmen-semibold-font darkcolor ${
                    disableText === true ? "opacity-light" : "opacity-dark"
                  }`}
                >
                  Maximum Tokens
                </h6>
                <h6
                  className={`Carmen-semibold-font darkcolor value ${
                    disableText === true ? "opacity-light" : "opacity-dark"
                  }`}
                >
                  {data.Maximum_Tokens}
                </h6>
              </div>
              <input
                type="range"
                min="1"
                max="1000"
                value={data.Maximum_Tokens}
                name="Maximum_Tokens"
                onChange={handleChange}
                step={1}
                disabled={disableText}
              />
            </div>

            <div className="range-div" ref={modalRef}>
              <div className="detail">
                <h6 className="Carmen-semibold-font darkcolor">Model</h6>
              </div>
              <div className="model-div" onClick={handleshowModal}>
                <h4
                  className={`Carmen-regular-font ${
                    data.Model ? "darkcolor" : "greycolor opacity-25"
                  }`}
                >
                  {data.Model ? data.Model : "Select Model"}
                </h4>
                {showmodal ? (
                  <Icon icon="mingcute:up-line" />
                ) : (
                  <Icon icon="mingcute:down-line" />
                )}
                <div
                  className={`model-content ${
                    showmodal === false ? "modal-hide" : "modal-show"
                  }`}
                >
                  <p
                    className="Carmen-regular-font greycolor"
                    onClick={() =>
                      setData((prev) => ({
                        ...prev,
                        Model: "GPT-3.5-turbo-0125",
                      }))
                    }
                  >
                    GPT-3.5-turbo-0125
                  </p>
                  <p
                    className="Carmen-regular-font greycolor"
                    onClick={() =>
                      setData((prev) => ({
                        ...prev,
                        Model: "GPT-4",
                      }))
                    }
                  >
                    GPT-4
                  </p>
                  <p
                    className="Carmen-regular-font greycolor"
                    onClick={() =>
                      setData((prev) => ({
                        ...prev,
                        Model: "GPT-4-0314",
                      }))
                    }
                  >
                    GPT-4-0314
                  </p>
                  <p
                    className="Carmen-regular-font greycolor"
                    onClick={() =>
                      setData((prev) => ({
                        ...prev,
                        Model: "GPT-4-0613",
                      }))
                    }
                  >
                    GPT-4-0613
                  </p>
                  <p
                    className="Carmen-regular-font greycolor"
                    onClick={() =>
                      setData((prev) => ({
                        ...prev,
                        Model: "GPT-4-turbo",
                      }))
                    }
                  >
                    GPT-4-turbo
                  </p>
                  <p
                    className="Carmen-regular-font greycolor"
                    onClick={() =>
                      setData((prev) => ({
                        ...prev,
                        Model: "GPT-4-1106-preview",
                      }))
                    }
                  >
                    GPT-4-1106-preview
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="dates">
            <div className="dates-inner">
              <h5 className="darkcolor Carmen-bold-font">Search</h5>
              <div className="search-div">
                <img
                  src="/assets/icons/search.svg"
                  alt="..."
                  className="pointer"
                />
                <input
                  type="text"
                  placeholder="Search here"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="contactTable-outer">
            <div className="contact-table">
              <div className="table-head">
                <div className="first">
                  <p className="darkcolor Carmen-medium-font">Meeting Name</p>
                </div>
                <div className="second">
                  <p className="darkcolor Carmen-medium-font">Organizer</p>
                </div>
                <div className="second">
                  <p className="darkcolor Carmen-medium-font">Type</p>
                </div>
                <div className="third">
                  <p className="darkcolor Carmen-medium-font">Meeting Link</p>
                </div>
              </div>
              <div className="table-body customscrollbar">
                {meetingLoader ? (
                  <div
                    className="d-flex align-items-center m-auto"
                    style={{ height: "100px" }}
                  >
                    <span className="spinner"></span>
                  </div>
                ) : filteredMeetingLinks && filteredMeetingLinks.length > 0 ? (
                  filteredMeetingLinks.map((item, index) => (
                    <div
                      key={index}
                      className={`contact-detail ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveIndex(index); // Update the active index
                        setData((prev) => ({
                          ...prev,
                          hubspot_meetinglink: item.link, // Set link to state
                        }));
                        handleClick(index); // Trigger click handler if necessary
                      }}
                    >
                      <div className="first">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.name}
                        </p>
                      </div>
                      <div className="second">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.slug}
                        </p>
                      </div>
                      <div className="second">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.type}
                        </p>
                      </div>
                      <div className="third">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.link}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="m-auto py-4">No data</p>
                )}
              </div>
            </div>
          </div>

          <div className="bottom">
            <Button
              text="Back"
              className="secondary-btn w-100 fs-20"
              maxWidth="157px"
              padding="12px 36px"
              onClick={navigateToscript}
            />

            <Button
              text="Finish"
              className="primary-button w-100 fs-20 "
              maxWidth="157px"
              padding="12px 36px"
              onClick={!updateagent ? handleShow : handleUpdate}
              disabled={createbtnloader ? true : false}
            />
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="advance-save"
          >
            <Modal.Body>
              <img src="/assets/icons/congrats.svg" alt="..." />
              <div>
                <h4 className="Carmen-semibold-font primarycolor">
                  Congratulations
                </h4>
                <h4 className="Carmen-bold-font secondarycolor">
                  Agent Created Successfully
                </h4>
              </div>
              <Button
                text="Finish"
                className="primary-button w-100 fs-20 mx-auto"
                maxWidth="207px"
                padding="12px 36px"
                onClick={handleClose}
              />
            </Modal.Body>
          </Modal>

          <Outlet />
        </section>
      </div>
    </div>
  );
}

export default AdvanceScript;
