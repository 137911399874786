import React, { useContext, useEffect, useRef, useState } from "react";
import "./signin.scss";
import Input from "../input/input";
import Button from "../button/button";
import { NavLink } from "react-browser-router";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleSignInButton from "../../helperFunctions/Google";
import { LoginContext, UserHubspotContext } from "../../App";
function Signin() {
  const Apiurl = process.env.REACT_APP_API_KEY;
  const logintoken = localStorage.getItem("authToken");
  const { loginStatus, setLoginStatus } = useContext(LoginContext);
  const { hubspotData, setHubspotData } = useContext(UserHubspotContext);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const currentToastId = useRef("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const loginApi = () => {
    setLoader(true);
    axios
      .post(`${Apiurl}/login`, {
        email: data.email,
        password: data.password,
      })

      .then((res) => {
        const token = res.data.token;

        localStorage.setItem("authToken", token);
        if (currentToastId.current) {
          toast.dismiss(currentToastId.current);
        }
        toast.success("Login Successfully", {
          toastId: "loginsuccess",
        });
        setLoader(false);

        if (hubspotData) {
          navigate("/dashboard");
        } else {
          navigate("/connect-to-hubspot");
        }
      })
      .catch((err) => {
        currentToastId.current = toast.error(err?.response?.data?.error, {
          toastId: "loginfailure",
        });
        console.log(err);
        setLoader(false);
        return;
      });
  };

  useEffect(() => {
    setLoginStatus(false);
  }, []);

  return (
    <>
      <section className="signin-sec">
        <div className="inner">
          <div className="inner-content">
            <div className="top">
              <h2 className="darkcolor Carmen-bold-font">
                Sign in to your account
              </h2>
              {/* <GoogleSignInButton /> */}
            </div>
            <div className="center-line"></div>
            <div className="bottom">
              <div className="inputs">
                <Input
                  label="Email address"
                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  onChange={handleChange}
                  value={data.email}
                />
                <Input
                  label="Password"
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  onChange={handleChange}
                  value={data.password}
                />
                <Button
                  text="Sign in"
                  className="primary-button w-100 fs-20"
                  maxWidth="356px"
                  padding="13px"
                  onClick={loginApi}
                  disabled={loader ? true : false}
                />
              </div>
              <div className="bottom-anchors">
                <NavLink
                  to="/password-recovery"
                  className="forget-link small darkcolor Carmen-regular-font"
                >
                  Forgot your password?
                </NavLink>
                {/* <NavLink
                  to="/signup"
                  className="signup-link Carmen-regular-font primarycolor"
                >
                  Sign up
                </NavLink> */}
              </div>
            </div>
          </div>
          <div className="back-layer"></div>
        </div>
        <div className="outer-content">
          <p className="Carmen-regular-font">
            By using AI Lead Generation you agree to our
            <a href="#" className="primarycolor">
              {" "}
              Terms of Service{" "}
            </a>
            ,
            <a href="#" className="primarycolor">
              {" "}
              Privacy
            </a>{" "}
            and
            <a href="#" className="primarycolor">
              {" "}
              Security{" "}
            </a>{" "}
            policies.
          </p>
        </div>
      </section>
    </>
  );
}

export default Signin;
